import { getRoute, PATH } from '@/utils/routes';
import React, { useEffect, useState } from 'react';
import styles from './megaMenu.module.scss';
import { MoreCarat, LessCarat } from './svgIcons';
import router from 'next/router';
import { useSelector } from 'react-redux';

const RenderCategory = (props) => {
  const { categories, onClose, collectionId } = props;
  const [currCategories, setCurrCategories] = useState([]);
  const [showMoreLess, setShowMoreLess] = useState({
    more: false,
    less: false,
  });

  const { storeData } = useSelector((state) => ({
    storeData: state.storeReducer.store,
  }));

  useEffect(() => {
    if (categories.length < 36) {
      setCurrCategories(categories);
    } else if (categories.length >= 36) {
      setShowMoreLess({ more: true, less: false });
      setCurrCategories(categories.slice(0, 36));
    }
  }, [categories]);

  useEffect(() => {
    if (showMoreLess.more) {
      setCurrCategories(categories.slice(0, 36));
    } else {
      setCurrCategories(categories);
    }
  }, [showMoreLess]);

  const totalCategories = currCategories?.length;
  const maxCols = Math.min(6, Math.ceil(totalCategories / 6));
  const maxRows = Math.max(6, Math.ceil(totalCategories / maxCols));

  const handleCategoryClick = (category) => {
    if (category.isMoreLess) return;
    router.push(
      getRoute(
        `${PATH.collectionCategories(collectionId)}?cid=${category.id}`,
        storeData?.store_info?.domain
      ),
      undefined,
      { shallow: true, scroll: true }
    );
    onClose && onClose();
  };

  function splitObjectsIntoGroups(objects, batchSize) {
    const result = [];
    for (let i = 0; i < objects.length; i += batchSize) {
      const batch = objects.slice(i, i + batchSize);
      result.push(batch);
    }
    return result;
  }

  const displayMoreLess = (type) => {
    return (
      <div
        className="flex"
        style={{ gap: '2px', alignItems: 'center', cursor: 'pointer' }}
        onClick={handleMoreLessClick}
      >
        <span style={{ color: '#000' }}>{type}</span>
        {showMoreLess.more ? <MoreCarat /> : <LessCarat />}
      </div>
    );
  };

  const handleMoreLessClick = () => {
    if (showMoreLess.more) {
      setShowMoreLess({ more: false, less: true });
    } else {
      setShowMoreLess({ more: true, less: false });
    }
  };

  const splittedCategories = splitObjectsIntoGroups(currCategories, maxRows);
  if (currCategories.length >= 36) {
    splittedCategories[maxCols - 1]?.push({
      isMoreLess: true,
      type: showMoreLess.more ? 'More' : 'Less',
    });
  }

  const categoriesGrid = [];
  for (let row = 0; row < maxRows; row++) {
    categoriesGrid.push([]);
    for (let col = 0; col < maxCols; col++) {
      const index = row + col * maxRows;
      if (index < totalCategories) {
        categoriesGrid[row].push(currCategories[index]);
      } else {
        categoriesGrid[row].push(null); // Add a placeholder for empty cells
      }
    }
  }

  return (
    <div className={styles.categoriesWrapper} style={{ width: '700px', gap: '24px' }}>
      {splittedCategories.map((col, colIndex) => (
        <div key={colIndex} className={styles.categoryColumn}>
          {col.map((category, rowIndex) => (
            <div
              key={colIndex}
              className={styles.categoryItem}
              onClick={() => handleCategoryClick(category)}
            >
              {category.isMoreLess && categories.length > 36
                ? displayMoreLess(category.type)
                : category.name}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default RenderCategory;
